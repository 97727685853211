

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(58%) sepia(92%) saturate(600%) hue-rotate(2deg) brightness(100%) contrast(100%);
}

.carousel-item img {
  border: 2px solid #4793AF;
}
