@media (max-width: 500px) {
    .logo-container {
      margin-left: 20px; /* Adjust the margin-left as needed */
    }
  }
  

  @media (max-width: 700px) {
    .image-container {
      margin-left: 70px; /* Adjust the left margin as needed */
    }
    .custom-list{
      margin-left: -30px;
    }
    
  }

  @media (max-width: 400px) {
    .image-container {
      margin-left: 55px; /* Adjust the left margin as needed */
    }
  }

.social{
  margin-right: 650px;
}

@media (max-width: 700px) {
  .social{
    margin-right: 0px;
  }
}

